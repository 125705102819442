.userModalOuter {
  position: fixed;
  background-color: white;
  width: 120px;
  height: 180px;
  border: 1px solid rgba(0,0,0,0.16);
  top: 90px;
  right: 15;
}

.userModalButton {
  margin-top: 20px;
  height: 30px;
  border: none;
  background-color: white;
  border-bottom: 1px solid #e9ecef;
}
