.chatOuterCotainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 12vh;
  left: 0;
  bottom: 0;
  right: 0;
}

.chatInnerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-left: 2px solid #D3D3D3;
  border-right: 2px solid #D3D3D3;
}

.chatForm {
  display: flex;
  width: 100%;
  border: 2px solid #D3D3D3;
}

.chatInput {
  border: none;
  border-radius: 0;
  padding: 5%;
  width: 80%;
  font-size: 1.2em;
}

.chatInput:focus {
  outline: none;
}

.messageSendBtn {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #608FBA;
  padding: 20px;
  border: none;
  width: 20%;
}

@media (min-width: 600px) {
  .chatInnerContainer, .chatForm {
    width: 70%;
  }
}
