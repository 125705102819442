*{
  box-sizing: border-box;
}

.menuOuterContainer {
  display: inline-flex;
}

.item {
  height: 60px;
  border: 1px;;
  color: black;
  cursor: pointer;
  margin-right: 30px;
}

.userImg {
  width: 50px;
  height: 50px;
  border-radius: 2em;
}
