* {
  box-sizing: border-box;
}

body {
  font-family: 'Malgun Gothic', Helvetica, sans-serif;
  font-size: 12px;
}

.detailPostOuterContainer {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.detailPostInnerContainer {
  width: 100%;
}

.detailPostButtonOuter {
  display: flex;
  justify-content: space-between;
}

.detailPostButton {
  margin-bottom: 20px;
  background-color: #608FBA;
  color: white;
  border: 1px solid #e9ecef;
  border-radius: 1em;
  width: 70px;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.postInfoOuter {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.postInfoItem {
  border: 1px solid #e9ecef;
  padding: 1em;
  white-space: nowrap;
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mt-20 {
  margin-top: 20px;
  clear: both;
}

button {
  cursor: pointer;
}

.detailContent {
  border: 1px solid #e9ecef;
  padding: 15px 20px;
  padding: 15px 20px;
  line-height: 1.6em;
  text-align: left;
  white-space: pre-line;
  height: 40vh;
  overflow-y: auto;
}

.subscribe {
  width: 20px;
  height: 20px;
}

@media (min-width: 600px) {
  .detailPostInnerContainer {
    width: 70%;
  }
}
