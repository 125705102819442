.subscribeOuter {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscibeInner {
  width: 100%;
  text-align: center;
}

.noInterestPosts {
  text-align: center;
  align-items: center;
}

@media (min-width:600px) {
  .subscibeInner {
    width: 70%;
  }
}
