@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');

*{
  box-sizing: border-box;
}

.headerOuter {
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  background-color: white;
  font-size: 20px;
  font-family: 'Ubuntu', sans-serif;
  color: #1A1A1D;
  height: 12vh;
  border-bottom: 1px solid #e9ecef;
  top: 0;
  left: 0;
  right: 0;
}

.headerIcon {
  width: 50px;
  height: 50px;
  color: #1A1A1D;
  margin-left: 30px;
  text-decoration: none;
  font-weight: bold;
  color: rgba(38, 34, 34, 0.66);
}

@media (min-width: 600px) {
  .headerOuter {
    justify-content: center;
  }

  .menuComponentOuter {
    position: absolute;
    right: 0;
  }
}
