@import url('https://fonts.googleapis.com/css2?family=Jua&family=Nanum+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.postPageOuterContainer {
  margin-top: 12vh;
  display: flex;
  justify-content: center; 
  text-align: center;
  height: 100%;
}

.postPageInnerContainer {
  margin-top: 70px;
  width: 100%;
}

.postBox {
  width: 100%;
  margin-bottom: 50px;
}

.placeBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.place {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-family: 'Jua', sans-serif;
  font-size: 1.7rem;
  color: rgba(38, 34, 34, 0.66);
  border: 1px solid #608FBA;
  width: 130px;
  height: 50px;
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.5s;
}

.place:hover {
  background-color: #608FBA;
  color: white;
}

.placeName {
  flex-shrink: 0;
}

.arrow {
  font-size: 1.4rem;
  color: #608FBA;
}

.date {
  font-size: 22px;
  margin: 40px;
  letter-spacing: .1em;
  font-family: 'Squada One', cursive;
  color: rgba(38, 34, 34, 0.66);
}

.dateBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dateItem {
  flex-shrink: 0;
}

.IoIoButton {
  cursor: pointer;
  border: none;
  background-color: white;
  padding: 0;
}

.newPostButton {
  background-color: #608FBA;
  color: white;
  border: 1px;
  border-radius: 1em;
  width: 70px;
  height: 30px;
  margin: 5px;
  float: right;
}

.arrowBtn {
  width: 20px;
  height: 20px;
  color: rgba(38, 34, 34, 0.66);
}

.arrowBtn:hover {
  color: #608FBA;
  transition: 0.5s;
}

button:focus {
  outline: none;
}

.categoryBox {
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  border-bottom: 1px solid #e9ecef;
}

.category {
  flex-wrap: nowrap;
  flex-shrink: 0;
  overflow: hidden;
  font-family: 'Nanum Gothic', sans-serif;
  margin-left: 3px;
  margin-right: 3px;
  width: 12.5%;
  text-align: center;
  white-space: nowrap
}

.noPosts {
  padding: 20px 0;
  padding: 20px 25px;
}

@media (min-width: 600px) {
  .postPageInnerContainer {
    width: 80%;
  }
}
