@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.modalOuterContainer {
  z-index: 8;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12vh;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.16);
}

.modalInnerContainer {
  position: relative;
  text-align: center;
  width: 350px;
  height: 500px;
  overflow-y: auto;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.buttonOuter {
  font-family: 'Jua', sans-serif;
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.cityButton {
  border: 1px;
  border-radius: 1em;
  background-color: #608FBA;
  color: white;
  width: 70px;
  height: 30px;
  cursor: pointer;
  margin: 5px;
}

.stationButton {
  border: 1px solid #608FBA;
  border-radius: 1em;
  width: 70px;
  height: 30px;
  cursor: pointer;
  margin: 5px;
}

.searchOuter {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.searchStationInput {
  border: 0;
  background-color: #608fba57;
  border-radius: 0.3em;
  font-size: 1rem;
  width: 50%
}

ul {
  padding-left: 0;
  width: 50%;
  list-style: none;
  text-align: left;
  height: 60px;
  overflow-y: auto;
}

li:hover {
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  background-color: #608fba57;
  cursor: pointer;
}

button, input {
  font-family: 'Nanum Gothic', sans-serif;
}

@media (min-width: 600px) {
  .modalInnerContainer {
    width: 500px;
  }
}
