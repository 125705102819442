@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.chatRoomOuter {
  font-family: 'Nanum Gothic', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  height: 100px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s;
}
.chatRoomOuter:hover {
  background-color: rgba(96, 142, 186, 0.384);
  color: white;
}

.partner {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.post {
  margin-right: 20px;
}

.inChatRoomPostDepdate {
  color: #868e96;
}

.inChatRoomPost {
  display: flex;
}

.chatRoomPostItems {
  align-items: center;
}

.partnerImg {
  width: 50px;
  height: 50px;
  border-radius: 2em;
  margin-right: 20px;
}

.partnerName {
  font-weight: bold;
}

.chatRoomOut {
  border: none;
  background-color: #608FBA;
  color: white;
  margin-left: 20px;
  border-radius: 1em;
  transition: 0.5s;
  width: 50px;
  height: 50px;
}

.chatRoomOut:hover {
  background-color: white;
  color: #608FBA;
}

@media (min-width: 600px) {
  .chatRoomOuter {
    width: 70%;
  }
}
