.infoBarOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #608FBA;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 60px;

}

.leftInnerContainer {
  align-items: center;
  margin-left: 5%;
  color: white;
  cursor: pointer;
}

.rightInnerContainer {
  justify-content: flex-end;
  margin-right: 5%;
  cursor: pointer;
  color: white;
}

.deleteOutline {
  width: 20px;
  height: 20px;
}

@media (min-width: 600px) {
  .infoBarOuter {
    width: 70%;
  }
}
