*{
  box-sizing: border-box;
}

#root {
  height: 100vh;
}

.loginOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: white;
}

.heading {
  color: #1A1A1D;
  font-size: 40px;
  border-bottom: 2px solid #4c4747;
}

.loginButton {
  cursor: pointer;
}

.mt-20 {
  margin-top: 20px;
}
