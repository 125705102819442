*{
  box-sizing: border-box;
}

.mainOuterContainer {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
}

.mainInnerContainer {
  border: 1px solid #e9ecef;
  border-radius: 1em;
  margin: 110px;
  padding: 50px;
}

.TrainOperationOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
}

.itemBox {
  margin: 20px;
}

.mainPageInput {
  padding: 15px 20px;
  border: 1px;
  margin: 20px;
  border-bottom: 1px solid #e9ecef;
  cursor: pointer;
}

.searchButton {
  padding: 20px;
  border: 1px;
  border-radius: 1em;
  background-color: #608FBA;
  color:white;
  cursor: pointer;
}
