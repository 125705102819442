@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

.chatOuter {
  z-index: 8;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12vh;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.16);
}

.chatInner {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  font-family: 'Jua', sans-serif;
  font-size: 16px;
  color: rgba(38, 34, 34, 0.66);
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.chatButton {
  border: 1px solid #608FBA;
  border-radius: 1em;
  width: 70px;
  height: 30px;
  cursor: pointer;
  margin-top: 10px;
  margin: 5px;
  transition: 0.5s;
}

.chatButton:hover {
  border: none;
  background-color: #608FBA;
  color: white;
}
