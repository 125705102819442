.commentForm {
  display: flex;
}

.commentTestarea {
  width: 100%;
  border-radius: 5px;
  padding: 15px 20px;
  border: 1px;
  border-bottom: 1px solid #e9ecef;
  margin: 0;
  height: 44px;
  width: 1232px;
}

.commentSubmitButton {
  flex-shrink: 0;
  width: 20%;
  height: 45px;
  cursor: pointer;
  border: 1px;
  border-radius: 1em;
  border: 1px solid #608FBA;
  margin-left: 10px;
  transition: 0.5s;
}

.commentSubmitButton:hover {
  color: white;
  background-color: #608FBA;
  border: none;
}

.singleCommentsOuter {
  display: flex;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 20px;
  margin: 20px;
}

.userName {
  flex-shrink: 0;
  font-weight: 600;
  font-size: 14px;
}

.singleCommentImg{
  width: 32px;
  height: 32px;
  border-radius: 2em;
  margin-right: 10px;
}

.contentBox {
  margin-left: 10px;
  width: 770px;
  text-align: left;
}

.postTime {
  white-space: nowrap;
  float: right;
  color: #868e96;
}

.singleCommentContent {
  flex-shrink: 0;
  flex-wrap: wrap;
  font-size: 12px;
}

.deleteComment {
  margin-left: 10px;
  white-space: nowrap;
  border: 1px solid #608FBA;
  width: 40px;
  height: 30px;
  border-radius: 1em;
  transition: 0.5s;
}

.deleteComment:hover {
  border: none;
  background-color: #608FBA;
  color: white;
}

.singleCommentImg {
  cursor: pointer;
}
