.messages {
  padding: 5% 0;
  flex-grow: 1;
  overflow-y: auto;
}

.messageOuter {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.messageInner {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #608FBA;
}

.backgroundLight {
  background: #F3F3F3;
}

.senderImg {
  width: 50px;
  height: 50px;
  border-radius: 2em;
  margin-right: 10px;
}

.messageUserName {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
