.postOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  line-height: 26px;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 20px;
  cursor: pointer;
  transition: 0.5s;
}

.postOuter:hover {
  background-color: rgba(96, 142, 186, 0.384);
  color: white;
}

.postItem {
  flex-wrap: nowrap;
  flex-shrink: 0;
  margin-left: 3px;
  margin-right: 3px;
  width: 12.5%;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.postTitle {
  display: block;
  font-weight: 600;
  color: #212529;
  font-size: 16px;
  line-height: 18px;
  margin-top: 10px;
  white-space: nowrap;

}

.postContent {
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  margin-top: 6px;
  white-space: nowrap;
  overflow-y: auto;
}

.test {
  color: #868e96;
}
