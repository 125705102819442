@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

.mypageOuter {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mypageInner {
  width: 100%;
  text-align: center;
}

h2 {
  font-family: 'Jua', sans-serif;
  color: rgba(38, 34, 34, 0.66);
}

@media (min-width: 600px) {
  .mypageInner {
    width: 70%;
  }
}
