*{
  box-sizing: border-box;
}

.newPostOuterContainer {
  margin-top: 120px;
  display: flex;
  text-align: center;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.newPostInnerContainer {
  width: 50%;
}

.newPostInput {
  width: 100%;
  padding: 15px 20px;
}

.newPostTextarea {
  width: 100%;
  height: 300px;
  padding: 15px 20px;
  margin-top: 20px;
}

.newPostSubmitButton {
  border: none;
  border-radius: 1em;
  background-color: #608FBA;
  color: white;
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 50px;
}

.mt-20 {
  margin-top: 20px;
}

.m-30 {
  margin: 30px;
  cursor: pointer;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}
