@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jua&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jua&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jua&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jua&family=Nanum+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Squada+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap);
.userModalOuter {
  position: fixed;
  background-color: white;
  width: 120px;
  height: 180px;
  border: 1px solid rgba(0,0,0,0.16);
  top: 90px;
  right: 15;
}

.userModalButton {
  margin-top: 20px;
  height: 30px;
  border: none;
  background-color: white;
  border-bottom: 1px solid #e9ecef;
}

*{
  box-sizing: border-box;
}

.menuOuterContainer {
  display: inline-flex;
}

.item {
  height: 60px;
  border: 1px;;
  color: black;
  cursor: pointer;
  margin-right: 30px;
}

.userImg {
  width: 50px;
  height: 50px;
  border-radius: 2em;
}

*{
  box-sizing: border-box;
}

.headerOuter {
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  background-color: white;
  font-size: 20px;
  font-family: 'Ubuntu', sans-serif;
  color: #1A1A1D;
  height: 12vh;
  border-bottom: 1px solid #e9ecef;
  top: 0;
  left: 0;
  right: 0;
}

.headerIcon {
  width: 50px;
  height: 50px;
  color: #1A1A1D;
  margin-left: 30px;
  text-decoration: none;
  font-weight: bold;
  color: rgba(38, 34, 34, 0.66);
}

@media (min-width: 600px) {
  .headerOuter {
    justify-content: center;
  }

  .menuComponentOuter {
    position: absolute;
    right: 0;
  }
}

.postOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  line-height: 26px;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 20px;
  cursor: pointer;
  transition: 0.5s;
}

.postOuter:hover {
  background-color: rgba(96, 142, 186, 0.384);
  color: white;
}

.postItem {
  flex-wrap: nowrap;
  flex-shrink: 0;
  margin-left: 3px;
  margin-right: 3px;
  width: 12.5%;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.postTitle {
  display: block;
  font-weight: 600;
  color: #212529;
  font-size: 16px;
  line-height: 18px;
  margin-top: 10px;
  white-space: nowrap;

}

.postContent {
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  margin-top: 6px;
  white-space: nowrap;
  overflow-y: auto;
}

.test {
  color: #868e96;
}

.loadingOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.mypageOuter {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mypageInner {
  width: 100%;
  text-align: center;
}

h2 {
  font-family: 'Jua', sans-serif;
  color: rgba(38, 34, 34, 0.66);
}

@media (min-width: 600px) {
  .mypageInner {
    width: 70%;
  }
}

.subscribeOuter {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscibeInner {
  width: 100%;
  text-align: center;
}

.noInterestPosts {
  text-align: center;
  align-items: center;
}

@media (min-width:600px) {
  .subscibeInner {
    width: 70%;
  }
}

.infoBarOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #608FBA;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 60px;

}

.leftInnerContainer {
  align-items: center;
  margin-left: 5%;
  color: white;
  cursor: pointer;
}

.rightInnerContainer {
  justify-content: flex-end;
  margin-right: 5%;
  cursor: pointer;
  color: white;
}

.deleteOutline {
  width: 20px;
  height: 20px;
}

@media (min-width: 600px) {
  .infoBarOuter {
    width: 70%;
  }
}

.messages {
  padding: 5% 0;
  flex-grow: 1;
  overflow-y: auto;
}

.messageOuter {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.messageInner {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #608FBA;
}

.backgroundLight {
  background: #F3F3F3;
}

.senderImg {
  width: 50px;
  height: 50px;
  border-radius: 2em;
  margin-right: 10px;
}

.messageUserName {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.chatOuterCotainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 12vh;
  left: 0;
  bottom: 0;
  right: 0;
}

.chatInnerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-left: 2px solid #D3D3D3;
  border-right: 2px solid #D3D3D3;
}

.chatForm {
  display: flex;
  width: 100%;
  border: 2px solid #D3D3D3;
}

.chatInput {
  border: none;
  border-radius: 0;
  padding: 5%;
  width: 80%;
  font-size: 1.2em;
}

.chatInput:focus {
  outline: none;
}

.messageSendBtn {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #608FBA;
  padding: 20px;
  border: none;
  width: 20%;
}

@media (min-width: 600px) {
  .chatInnerContainer, .chatForm {
    width: 70%;
  }
}

*{
  box-sizing: border-box;
}

#root {
  height: 100vh;
}

.loginOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: white;
}

.heading {
  color: #1A1A1D;
  font-size: 40px;
  border-bottom: 2px solid #4c4747;
}

.loginButton {
  cursor: pointer;
}

.mt-20 {
  margin-top: 20px;
}

*{
  box-sizing: border-box;
}

.mainOuterContainer {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
}

.mainInnerContainer {
  border: 1px solid #e9ecef;
  border-radius: 1em;
  margin: 110px;
  padding: 50px;
}

.TrainOperationOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
}

.itemBox {
  margin: 20px;
}

.mainPageInput {
  padding: 15px 20px;
  border: 1px;
  margin: 20px;
  border-bottom: 1px solid #e9ecef;
  cursor: pointer;
}

.searchButton {
  padding: 20px;
  border: 1px;
  border-radius: 1em;
  background-color: #608FBA;
  color:white;
  cursor: pointer;
}

.modalOuterContainer {
  z-index: 8;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12vh;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.16);
}

.modalInnerContainer {
  position: relative;
  text-align: center;
  width: 350px;
  height: 500px;
  overflow-y: auto;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.buttonOuter {
  font-family: 'Jua', sans-serif;
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.cityButton {
  border: 1px;
  border-radius: 1em;
  background-color: #608FBA;
  color: white;
  width: 70px;
  height: 30px;
  cursor: pointer;
  margin: 5px;
}

.stationButton {
  border: 1px solid #608FBA;
  border-radius: 1em;
  width: 70px;
  height: 30px;
  cursor: pointer;
  margin: 5px;
}

.searchOuter {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.searchStationInput {
  border: 0;
  background-color: #608fba57;
  border-radius: 0.3em;
  font-size: 1rem;
  width: 50%
}

ul {
  padding-left: 0;
  width: 50%;
  list-style: none;
  text-align: left;
  height: 60px;
  overflow-y: auto;
}

li:hover {
  font-family: 'Nanum Gothic', sans-serif;
  color: white;
  background-color: #608fba57;
  cursor: pointer;
}

button, input {
  font-family: 'Nanum Gothic', sans-serif;
}

@media (min-width: 600px) {
  .modalInnerContainer {
    width: 500px;
  }
}

*{
  box-sizing: border-box;
}

.newPostOuterContainer {
  margin-top: 120px;
  display: flex;
  text-align: center;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.newPostInnerContainer {
  width: 50%;
}

.newPostInput {
  width: 100%;
  padding: 15px 20px;
}

.newPostTextarea {
  width: 100%;
  height: 300px;
  padding: 15px 20px;
  margin-top: 20px;
}

.newPostSubmitButton {
  border: none;
  border-radius: 1em;
  background-color: #608FBA;
  color: white;
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 50px;
}

.mt-20 {
  margin-top: 20px;
}

.m-30 {
  margin: 30px;
  cursor: pointer;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.commentForm {
  display: flex;
}

.commentTestarea {
  width: 100%;
  border-radius: 5px;
  padding: 15px 20px;
  border: 1px;
  border-bottom: 1px solid #e9ecef;
  margin: 0;
  height: 44px;
  width: 1232px;
}

.commentSubmitButton {
  flex-shrink: 0;
  width: 20%;
  height: 45px;
  cursor: pointer;
  border: 1px;
  border-radius: 1em;
  border: 1px solid #608FBA;
  margin-left: 10px;
  transition: 0.5s;
}

.commentSubmitButton:hover {
  color: white;
  background-color: #608FBA;
  border: none;
}

.singleCommentsOuter {
  display: flex;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 20px;
  margin: 20px;
}

.userName {
  flex-shrink: 0;
  font-weight: 600;
  font-size: 14px;
}

.singleCommentImg{
  width: 32px;
  height: 32px;
  border-radius: 2em;
  margin-right: 10px;
}

.contentBox {
  margin-left: 10px;
  width: 770px;
  text-align: left;
}

.postTime {
  white-space: nowrap;
  float: right;
  color: #868e96;
}

.singleCommentContent {
  flex-shrink: 0;
  flex-wrap: wrap;
  font-size: 12px;
}

.deleteComment {
  margin-left: 10px;
  white-space: nowrap;
  border: 1px solid #608FBA;
  width: 40px;
  height: 30px;
  border-radius: 1em;
  transition: 0.5s;
}

.deleteComment:hover {
  border: none;
  background-color: #608FBA;
  color: white;
}

.singleCommentImg {
  cursor: pointer;
}

.chatOuter {
  z-index: 8;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12vh;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.16);
}

.chatInner {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  font-family: 'Jua', sans-serif;
  font-size: 16px;
  color: rgba(38, 34, 34, 0.66);
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.chatButton {
  border: 1px solid #608FBA;
  border-radius: 1em;
  width: 70px;
  height: 30px;
  cursor: pointer;
  margin-top: 10px;
  margin: 5px;
  transition: 0.5s;
}

.chatButton:hover {
  border: none;
  background-color: #608FBA;
  color: white;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Malgun Gothic', Helvetica, sans-serif;
  font-size: 12px;
}

.detailPostOuterContainer {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.detailPostInnerContainer {
  width: 100%;
}

.detailPostButtonOuter {
  display: flex;
  justify-content: space-between;
}

.detailPostButton {
  margin-bottom: 20px;
  background-color: #608FBA;
  color: white;
  border: 1px solid #e9ecef;
  border-radius: 1em;
  width: 70px;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.postInfoOuter {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.postInfoItem {
  border: 1px solid #e9ecef;
  padding: 1em;
  white-space: nowrap;
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mt-20 {
  margin-top: 20px;
  clear: both;
}

button {
  cursor: pointer;
}

.detailContent {
  border: 1px solid #e9ecef;
  padding: 15px 20px;
  padding: 15px 20px;
  line-height: 1.6em;
  text-align: left;
  white-space: pre-line;
  height: 40vh;
  overflow-y: auto;
}

.subscribe {
  width: 20px;
  height: 20px;
}

@media (min-width: 600px) {
  .detailPostInnerContainer {
    width: 70%;
  }
}

.postPageOuterContainer {
  margin-top: 12vh;
  display: flex;
  justify-content: center; 
  text-align: center;
  height: 100%;
}

.postPageInnerContainer {
  margin-top: 70px;
  width: 100%;
}

.postBox {
  width: 100%;
  margin-bottom: 50px;
}

.placeBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.place {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-family: 'Jua', sans-serif;
  font-size: 1.7rem;
  color: rgba(38, 34, 34, 0.66);
  border: 1px solid #608FBA;
  width: 130px;
  height: 50px;
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.5s;
}

.place:hover {
  background-color: #608FBA;
  color: white;
}

.placeName {
  flex-shrink: 0;
}

.arrow {
  font-size: 1.4rem;
  color: #608FBA;
}

.date {
  font-size: 22px;
  margin: 40px;
  letter-spacing: .1em;
  font-family: 'Squada One', cursive;
  color: rgba(38, 34, 34, 0.66);
}

.dateBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dateItem {
  flex-shrink: 0;
}

.IoIoButton {
  cursor: pointer;
  border: none;
  background-color: white;
  padding: 0;
}

.newPostButton {
  background-color: #608FBA;
  color: white;
  border: 1px;
  border-radius: 1em;
  width: 70px;
  height: 30px;
  margin: 5px;
  float: right;
}

.arrowBtn {
  width: 20px;
  height: 20px;
  color: rgba(38, 34, 34, 0.66);
}

.arrowBtn:hover {
  color: #608FBA;
  transition: 0.5s;
}

button:focus {
  outline: none;
}

.categoryBox {
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  border-bottom: 1px solid #e9ecef;
}

.category {
  flex-wrap: nowrap;
  flex-shrink: 0;
  overflow: hidden;
  font-family: 'Nanum Gothic', sans-serif;
  margin-left: 3px;
  margin-right: 3px;
  width: 12.5%;
  text-align: center;
  white-space: nowrap
}

.noPosts {
  padding: 20px 0;
  padding: 20px 25px;
}

@media (min-width: 600px) {
  .postPageInnerContainer {
    width: 80%;
  }
}

.chatRoomOuter {
  font-family: 'Nanum Gothic', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  height: 100px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s;
}
.chatRoomOuter:hover {
  background-color: rgba(96, 142, 186, 0.384);
  color: white;
}

.partner {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.post {
  margin-right: 20px;
}

.inChatRoomPostDepdate {
  color: #868e96;
}

.inChatRoomPost {
  display: flex;
}

.chatRoomPostItems {
  align-items: center;
}

.partnerImg {
  width: 50px;
  height: 50px;
  border-radius: 2em;
  margin-right: 20px;
}

.partnerName {
  font-weight: bold;
}

.chatRoomOut {
  border: none;
  background-color: #608FBA;
  color: white;
  margin-left: 20px;
  border-radius: 1em;
  transition: 0.5s;
  width: 50px;
  height: 50px;
}

.chatRoomOut:hover {
  background-color: white;
  color: #608FBA;
}

@media (min-width: 600px) {
  .chatRoomOuter {
    width: 70%;
  }
}

.chatListOuter {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

